import {createRouter, createWebHistory } from 'vue-router';
import {useCustomerStore} from "@/stores/customerStore";

import dayjs from "dayjs";

import {$client, $utils} from "@/assets/js/utils";

import MainView from '@/views/Main.vue';
import KcbCertView from '@/views/common/KcbCert.vue';
import KakaoLoginView from "@/views/common/KakaoLogin.vue";

let param = {};

const checkLogin = async () => {
    if ($utils.isNotBlank(document.referrer)
            && !document.referrer.startsWith($utils.getUrl())) {
        sessionStorage.setItem('referrer', document.referrer);

        await $client.post('/svc/init?referer=' + document.referrer)
    }

    let customerStore = useCustomerStore();

    if ($utils.isBlank(customerStore.accessToken) || $utils.isNull(customerStore.customerInfo)) {
        param = { type: 'MAIN' };
    } else {
        param = { type: 'MAIN' };
    }

    return MainView;
}

const kakaoLogin = async () => {
    // code 값으로 access_token 을 가져옴
    const urlParams = new URLSearchParams(window.location.search);

    param = { code: urlParams.get('code') };

    return KakaoLoginView;
}

// 본인 인증 결과 조회 후 처리
async function kcbCert() {
    const urlParams = new URLSearchParams(window.location.search);

    param = { mdl_tkn: urlParams.get('mdl_tkn') };

    return KcbCertView;
}

async function partnerSave() {
    param = { type: 'MAIN' };

    const urlParams = new URLSearchParams(window.location.search);
    const partnerCode = urlParams.get('code');

    if ($utils.isNotBlank(partnerCode)) {
        // 접속 정보 저장
        const partnerInfo = {
            partner_code: partnerCode
            , inflow_date: dayjs().format('YYYY-MM-DD HH:mm:ss')
        };

        localStorage.setItem('partner_info', JSON.stringify(partnerInfo));

        // Welcome Page 조회
        await $client.post("/svc/partner/welcome/page/detail", { welcome_page_key: partnerCode }, function(response) {
            if ($utils.isNotBlank(response.welcome_page_uri)) {
                window.location.href = response.welcome_page_uri;
            }
        });
    }

    return MainView;
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'main',
            component: async () => {
                return await checkLogin();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/login',
            name: 'login',
            component: MainView,
            props: () => {
                return { param: { type: 'LOGIN' } }
            }
        },
        {
            path: '/join',
            name: 'join',
            component: MainView,
            props: () => {
                return { param: { type: 'JOIN' } }
            }
        },
        {
            path: '/kakao/login',
            name: 'kakao login',
            component: async () => {
                return await kakaoLogin();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/kcb/cert',
            name: 'kcb cert',
            component: async () => {
                return await kcbCert();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/partner',
            name: 'partner',
            component: async () => {
                return await partnerSave();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/'
        }
    ]
})

export default router
